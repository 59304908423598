import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { Link, graphql, useStaticQuery } from "gatsby";
import unified from "unified";
import markdown from "remark-parse";
import html from "remark-html";
import Layout from "../components/layout";
import ProductListItem from "../components/ProductListItem";
import { useMediaQuery } from "react-responsive";
import HeaderBottom from "../components/HeaderBottom";

const ProductsPageTemplate = ({ data, pageContext, location }) => {
  let quickLinkTags = data.tags.frontmatter.tags;

  console.log("data is: ", data);
  const products = data.allMarkdownRemark.edges;
  const siteTitle = data.site.siteMetadata.title;
  const siteUrl = data.site.siteMetadata.siteUrl;

  const {
    currentPage,
    numPages,
    productsPerPage,
    skip,
    limit,
    slug,
    regexp,
    type,
    pageid,
  } = pageContext;
  const totalProducts = data.allMarkdownRemark.totalCount;
  const realNumPages = Math.ceil(totalProducts / productsPerPage);
  const isFirst = currentPage === 1;
  const isLast = currentPage === realNumPages;
  const prevPage =
    slug + (currentPage - 1 === 1 ? "/" : "/" + (currentPage - 1).toString());
  const nextPage = slug + "/" + (currentPage + 1).toString();

  // Based on brand or tag, obtain object to get: pageName, pageDescription, pageImageHeader
  let pageName = "";
  let pageDescription = "";
  let pageImageHeader = "";
  console.log(pageid, data, `${type}`, data[`${type}`]);
  const pageObject = data[`${type}`]
    ? data[`${type}`].frontmatter[`${type}`].filter((e) => e.id == pageid)[0]
    : null;
  console.log("pageobj", pageObject);
  if (pageObject) {
    pageName = pageObject.name;
    pageDescription = pageObject.description;
    pageImageHeader = pageObject.imageHeader;
  }

  console.log("loc", location, location.origin);
  console.log("products", data, products, pageName, regexp);
  console.log(
    "PAGIN",
    skip,
    limit,
    currentPage,
    numPages,
    realNumPages,
    totalProducts,
    productsPerPage,
    isFirst,
    isLast
  );

  console.log("pageContext", pageContext);

  // useEffect(() => {
  //   console.log("testomani", window.$("#prodect-modal"));
  // });
  const isMoreThan345 = useMediaQuery({ query: "(min-width: 345px)" });
  const is789 = useMediaQuery({ query: "(max-width: 789px)" });
  const PAGEINFOSHORTLENGTH = is789 ? 195 : 320;

  let pageDescriptionShort =
    pageDescription.length > PAGEINFOSHORTLENGTH
      ? pageDescription.substring(0, PAGEINFOSHORTLENGTH)
      : "";
  const [pageInfoReadMore, setPageInfoReadMore] = useState(
    pageDescriptionShort ? false : true
  );
  console.log("pageInfoReadMore", pageInfoReadMore);

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        bodyAttributes={{
          class: "site-layout--horizontal scroll-is--active",
        }}
      />
      <HeaderBottom />
      <main class="site-content" id="wrapper">
        <div class="widget-area widger-area--before-loop">
          <div class="widget widget-featured-product bg-image bg--ph-03">
            <div class="widget__subtitle">Featured Item</div>
            <h1 class="widget__title h2">
              <span class="color-primary">ROGUE ONE</span>Premium 180°
            </h1>
            <div class="widget__thumbnail">
              <img
                class=""
                src="/assets/images/products/fbb91d0b-cd42-4dc3-831c-a338e5be9d5b.jpeg"
                alt=""
              />
              <div class="widget__price">
                <a class="add-icon" href="shop-product.html"></a>
                <div class="widget__price-label">Get it for</div>
                <div class="widget__price-count">
                  Rp. <span class="color-primary">1.85 JT</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content shop-layout--style-2">
          {products &&
            products.map((p, index) => {
              console.log(p.node.frontmatter);
              console.log("ORIIGN", location.origin);
              return (
                <ProductListItem
                  prod={p.node.frontmatter}
                  slug={p.node.fields.slug}
                  origin={siteUrl}
                />
              );
            })}
        </div>
      </main>
    </Layout>
  );
};

export default ProductsPageTemplate;

export const pageQuery = graphql`
  query ProductsListQuery($skip: Int!, $limit: Int!, $regexp: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        frontmatter: { type: { eq: "product" }, tag: { regex: $regexp } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            name
            price
            date(locale: "id")
            thumbnail
            images {
              image
            }
            tag
            redLabel
            blackLabel
            tokpedUrl
            instaUrl
          }
          fields {
            slug
          }
        }
      }
    }
    brands: markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        brands {
          id
          name
          description
          imageHeader
        }
      }
    }
    tags: markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        tags {
          id
          name
          description
          imageHeader
        }
      }
    }
  }
`;
